<template>
  <div>
    <ReportCenterTitle v-if="!inside" title="orders" :count="count" />
    <h6 class="mt-2" v-else>
      <router-link to="/orders">{{ $t("orders") }} ({{ count }})</router-link>
    </h6>
    <Table
      :hideHeader="inside"
      :count="count"
      :headers="headers"
      no-search
      :loading="isLoading"
      :items="items"
      no-card
    >
      <template #user="{ item }">
        <div class="d-flex justify-content-between">
          <router-link :to="`/user/${item.userInfo.id}`">
            <span class="text-primary">{{ item.userInfo.fullname }}</span>
            <div>
              <small>
                {{ item.userInfo.email }} - {{ item.userInfo.phone }}
              </small>
            </div>
          </router-link>
          <span class="float-right" v-if="inside">
            {{ formatter(item.createdAt) }}
          </span>
        </div>
      </template>
      <template #createdAt="{ item }">
        {{ formatter(item.createdAt) }}
      </template>
      <template #product="{ item }">
        <router-link
          v-if="item.eventItemInfo"
          class="text-primary"
          :to="`/event-detail/${item.productInfo.id}/event-date/${item.eventItemInfo.id}`"
          >{{ formatter(item.eventItemInfo.date) }} -
        </router-link>
        {{ item.productInfo.title }}
        <div>
          <small>
            {{ item.productInfo.category.name }}
          </small>
          <small v-if="item.eventItemInfo"> - Etkinlik </small>
        </div>
      </template>
      <template #price="{ item }">
        <div class="mt-2" v-if="getSettings.departmentId !== 2">
          <strong v-if="item.status === 3 || item.status === 4">
            {{
              Currency(
                { price: item.price, discount: item.discount },
                item.currency || "TRY"
              )
            }}
          </strong>
          <div v-else>
            <small v-if="item.discount > 0 && item.price > 0" class="old-price">
              {{ Currency({ price: item.price }, item.currency || "TRY") }}
            </small>
            <div>
              <strong>
                {{
                  Currency(
                    { price: item.price, discount: item.discount },
                    item.currency || "TRY"
                  )
                }}
              </strong>
              <small v-if="item.discount > 0 && item.promotionCodeInfo === null"
                >(%{{ item.discount }})</small
              >
            </div>
          </div>
          <div v-if="item.promotionCodeInfo !== null" class="text-success">
            <small
              >{{ item.promotionCodeInfo.code }} (-{{
                item.promotionCodeInfo.discount
              }}%)</small
            >
          </div>
        </div>
      </template>
    </Table>
    <div v-if="!inside" class="d-flex justify-content-center">
      <BoostrapPagination
        @change="SET_PAGINATION"
        :page="page"
        :pages="pages"
      ></BoostrapPagination>
    </div>
  </div>
</template>

<script>
import ReportCenterTitle from "../Title.vue";
import Table from "../../Tables/index.vue";
import { formatter } from "gurhan/helpers/Date";
import { Currency } from "gurhan/helpers/Currency";
import BoostrapPagination from "gurhan/vue/BootstrapPagination.vue";

export default {
  components: {
    BoostrapPagination,
    ReportCenterTitle,
    Table,
  },
  props: {
    inside: {
      default: false,
      type: Boolean,
    },
    limit: {
      default: 10,
    },
  },
  data() {
    return {
      items: [],
      isLoading: true,
      count: 0,
      page: 1,
      headers: [
        {
          title: this.$t("user"),
          value: "user",
          col: 12,
          md: 3,
          lg: 3,
          xl: 2,
        },
        {
          title: this.$t("createdAt"),
          value: "createdAt",
          col: 12,
          md: 3,
          lg: 3,
          xl: 2,
        },
        {
          title: this.$t("productName"),
          value: "product",
          col: 12,
          md: 6,
          lg: 5,
          xl: 4,
        },
        {
          title: this.$t("productPrice"),
          value: "price",
          col: 12,
          md: 3,
          lg: 3,
          xl: 2,
        },
      ],
    };
  },
  computed: {
    pages() {
      return Math.ceil(this.count / 10);
    },
    getSettings() {
      return this.$store.state.User.settings;
    },
  },
  methods: {
    formatter,
    Currency,
    INSIDE_ORDERS() {
      this.headers = [
        {
          title: this.$t("user"),
          value: "user",
          col: 12,
          md: 12,
          lg: 12,
          xl: 12,
        },
        {
          title: this.$t("productName"),
          value: "product",
          col: 12,
          md: 9,
          lg: 9,
          xl: 9,
        },
        {
          title: this.$t("productPrice"),
          value: "price",
          col: 12,
          md: 3,
          lg: 3,
          xl: 3,
        },
      ];
    },
    SET_PAGINATION(page) {
      this.page = page;
      this.$router.replace({ query: { page } });
      this.GET_ITEMS();
    },
    async GET_ITEMS() {
      this.isLoading = true;
      const response = await this.$api.get(
        `Statistic/GetPurchaseProducts?page=${this.page}&limit=${this.limit}`
      );
      if (response.message === "OK") {
        this.items = response.data.items;
        this.count = response.data.count;
      }
      this.isLoading = false;
    },
  },
  mounted() {
    const q = this.$route.query;
    if (!isNaN(Number(q.page))) this.page = Number(q.page);
    this.GET_ITEMS();
    if (this.inside) this.INSIDE_ORDERS();
  },
};
</script>

<style></style>
